import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Box,
  Icon,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import { MdGroup } from "react-icons/md";

const UserTable = () => {
    const [data, setData] = useState([]); // Текущее состояние данных
    const [originalData, setOriginalData] = useState([]); // Исходные данные
  const [isChanged, setIsChanged] = useState(false); // Флаг изменений
  const [isLoading, setIsLoading] = useState(false); // Флаг загрузки
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  // Получение данных с бэкенда
  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/users?page=${currentPage}&limit=${itemsPerPage}`
      );
      const result = await response.json();
      setData(result.users);
      setOriginalData(result.users);
      setIsChanged(false);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [currentPage]);

  // Обработчик изменения поля
  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value }; // Изменяем конкретную строку и поле
    setData(newData);
    setIsChanged(true);
  };

  // Сохранение изменений
  const handleSave = async () => {
    setIsLoading(true);
  
    // Формируем объект с изменёнными полями
    const updatedUsers = {};
    data.forEach((row, index) => {
      const originalRow = originalData[index];
      const updatedFields = {};
  
      // Сравниваем каждое поле строки
      Object.keys(row).forEach((field) => {
        if (row[field] !== originalRow[field]) {
          updatedFields[field] = row[field];
        }
      });
  
      // Если есть изменения, добавляем их для отправки
      if (Object.keys(updatedFields).length > 0) {
        updatedUsers[row.client_id] = updatedFields;
      }
    });
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/users/update`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ updatedUsers }),
        }
      );
  
      if (response.ok) {
        alert("Данные успешно обновлены!");
        fetchUsers(); // Перезагружаем данные
      } else {
        console.error("Ошибка при сохранении данных.");
      }
    } catch (error) {
      console.error("Ошибка сети:", error);
    } finally {
      setIsLoading(false);
    }
  };
  

  // Пагинация
  const totalPages = Math.ceil(data.length / itemsPerPage);
  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
    <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
      <IconBox
        w="56px"
        h="56px"
        bg="white"
        float="left"
        icon={<Icon w="32px" h="32px" as={MdGroup} color="navy.500" />}
      />
      <Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">
        Пользователи
      </Text>
    </Flex>
  
    <Card w="100%" p={{ base: "20px", md: "40px" }}>
      <Box overflowX="auto">
        <Table variant="striped" size="sm">
          <Thead>
            <Tr>
              {data.length > 0 &&
                Object.keys(data[0]).map((key) => <Th key={key}>{key}</Th>)}
            </Tr>
          </Thead>
          <Tbody>
            {data.map((user, index) => (
              <Tr key={index}>
                {Object.keys(user).map((field) => (
                  <Td key={field}>
                    <Input
                      size="sm"
                      value={user[field] || ""}
                      onChange={(e) =>
                        handleInputChange(index, field, e.target.value)
                      }
                    />
                  </Td>
                ))}
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Box>

      {/* Пагинация */}
      <Flex mt={4} justify="center" align="center">
        <IconButton
          icon={<ArrowLeftIcon />}
          onClick={prevPage}
          isDisabled={currentPage === 1}
          mr={4}
        />
        <Text fontSize="sm">
          Страница {currentPage} из {totalPages || 1}
        </Text>
        <IconButton
          icon={<ArrowRightIcon />}
          onClick={nextPage}
          isDisabled={currentPage === totalPages}
          ml={4}
        />
      </Flex>

      {/* Кнопка сохранения */}
      <Button
        position="fixed"
        bottom="20px"
        right="20px"
        colorScheme="blue"
        size="lg"
        fontSize="15px"
        visibility={isChanged ? "visible" : "hidden"}
        isLoading={isLoading}
        onClick={handleSave}
      >
        Сохранить изменения
      </Button>
    </Card>
    </>
  );
};

export default UserTable;
