import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import { ChakraProvider } from '@chakra-ui/react';
import initialTheme from './theme/theme';
import { useState, useContext } from 'react';
import { UserProvider, UserContext } from "contexts/UserContext";
import { DataProvider } from "contexts/DataContext";

export default function Main() {

  const [currentTheme, setCurrentTheme] = useState(initialTheme);

  // Общая логика защищенного маршрута
  const ProtectedRoute = ({ children, requiredClientId }) => {
    const { user } = useContext(UserContext); // Получаем текущего пользователя из контекста
  
    if (!user) {
      // Если пользователь не авторизован, перенаправляем на страницу входа
      return <Navigate to="/auth/sign-in" />;
    }
  
    // Если требуется проверка client_id, а он не совпадает
    if (requiredClientId && user.client_id !== requiredClientId) {
      // Можно перенаправить на страницу ошибки доступа или другую страницу
      return <Navigate to="/auth/sign-in" />;
    }
  
    // Если все проверки пройдены, рендерим переданный компонент
    return children;
  };

  return (
    <UserProvider> 
      <DataProvider>
        <ChakraProvider theme={currentTheme}>
          <Routes>
            {/* Открытый доступ */}
            <Route path="/auth/*" element={<AuthLayout />} />

            {/* Доступ только для авторизованных пользователей */}
            <Route 
              path="/admin/*" 
              element={
                  <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
              } 
            />

            {/* Доступ с проверкой client_id */}
            <Route
              path="/protected/*"
              element={
                <ProtectedRoute requiredClientId={1}>
                  <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
                </ProtectedRoute>
              }
            />

            {/* Редирект по умолчанию */}
            <Route path="/" element={<Navigate to="/auth/sign-in" replace />} />
          </Routes>
        </ChakraProvider>
      </DataProvider>
    </UserProvider>
  );
}
