import { Flex, Text, SimpleGrid, Image, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Flag from "react-world-flags"; // Используем для флагов стран
import { useState, useEffect } from "react";
import euFlag from "assets/img/eu.jpg"; // Импортируем кастомный флаг Евросоюза

// Пример фиксированных курсов
const fixedRates = {
  "USD-RUB": 76.23,
  "RUB-USD": 0.013,
  "EUR-RUB": 85.67,
  "RUB-EUR": 0.012,
  "CNY-RUB": 10.74,
  "RUB-CNY": 0.093,
  "AED-RUB": 20.75,
  "RUB-AED": 0.048,
  "CNH-RUB": 10.64,
  "RUB-CNH": 0.094,
};

export default function CurrencyRates({ rates }) {
  const textColor = useColorModeValue('secondaryGray.900', 'white');

  useEffect(() => {
    // Здесь можно использовать API для обновления значений rates
    // setRates(dataFromAPI);
  }, []);

  const currencyPairs = [
    { pair: "USD-RUB", from: "us", to: "ru", rate: rates["USD-RUB"] },
    { pair: "RUB-USD", from: "ru", to: "us", rate: rates["RUB-USD"] },
    { pair: "EUR-RUB", from: "eu", to: "ru", rate: rates["EUR-RUB"], fromCustomFlag: euFlag },
    { pair: "RUB-EUR", from: "ru", to: "eu", rate: rates["RUB-EUR"], toCustomFlag: euFlag },
    { pair: "CNY-RUB", from: "cn", to: "ru", rate: rates["CNY-RUB"] },
    { pair: "RUB-CNY", from: "ru", to: "cn", rate: rates["RUB-CNY"] },
    { pair: "AED-RUB", from: "ae", to: "ru", rate: rates["AED-RUB"] },
    { pair: "RUB-AED", from: "ru", to: "ae", rate: rates["RUB-AED"] },
  ];

  return (
    <Card flexDirection="column" w="100%" p="20px">
      <Text
        color={textColor}
        fontSize="22px"
        fontWeight="700"
        mt="10px"
        mb="25px"
        lineHeight="100%"
      >
        Курсы валют на сегодня
      </Text>
      <SimpleGrid columns={2} spacing={4}>
        {currencyPairs.map((pair, index) => (
          <Flex key={index} flexDirection="column" align="center" justify="center" p={2} bg="gray.100" borderRadius="md">
            {/* Название пары валют */}
            <Text fontWeight="bold" mb={1}>{pair.pair.replace("-", " - ")}</Text>
            
            {/* Флаги и курс */}
            <Flex align="center">
              {pair.fromCustomFlag ? (
                <Image src={pair.fromCustomFlag} alt="EU Flag" width={6} height={4} mr={2} />
              ) : (
                <Flag code={pair.from} style={{ width: 24, height: 16, marginRight: 8 }} />
              )}
              <Text fontWeight="bold">{pair.rate}</Text>
              {pair.toCustomFlag ? (
                <Image src={pair.toCustomFlag} alt="EU Flag" width={6} height={4} ml={2} />
              ) : (
                <Flag code={pair.to} style={{ width: 24, height: 16, marginLeft: 8 }} />
              )}
            </Flex>
          </Flex>
        ))}
      </SimpleGrid>
    </Card>
  );
}
