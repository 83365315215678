import React, { useState } from "react";
import { Button, Box, Flex, Icon, Input, Text, VStack, HStack, Divider } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import { MdHandshake } from "react-icons/md";
const CurrencyParser = () => {
  const [cbrRates, setCbrRates] = useState(null);
  const [econoRates, setEconoRates] = useState(null);
  const [editableCbrRates, setEditableCbrRates] = useState({});
  const [editableEconoRates, setEditableEconoRates] = useState({});
  // Функция для получения курсов валют от ЦБР
  const fetchCbrRates = async () => {
    try {
      const response = await fetch("https://www.cbr-xml-daily.ru/daily_json.js");
      const data = await response.json();
  
      // Проверка наличия данных
      if (!data || !data.Valute) {
        throw new Error("Данные не найдены в ответе API.");
      }
  
      // Формируем корректный объект курсов валют
      const currencyPairs = {
        "USD - RUB": data.Valute.USD.Value,
        "RUB - USD": 1 / data.Valute.USD.Value,
        "EUR - RUB": data.Valute.EUR.Value,
        "RUB - EUR": 1 / data.Valute.EUR.Value,
        "CNY - RUB": data.Valute.CNY.Value,
        "RUB - CNY": 1 / data.Valute.CNY.Value,
        "AED - RUB": data.Valute.AED ? data.Valute.AED.Value : null,
        "RUB - AED": data.Valute.AED ? 1 / data.Valute.AED.Value : null,
      };
  
      // Дополнительные пары через RUB
      currencyPairs["EUR - USD"] = currencyPairs["EUR - RUB"] / currencyPairs["USD - RUB"];
      currencyPairs["USD - EUR"] = currencyPairs["USD - RUB"] / currencyPairs["EUR - RUB"];
      currencyPairs["CNY - USD"] = currencyPairs["CNY - RUB"] / currencyPairs["USD - RUB"];
      currencyPairs["USD - CNY"] = currencyPairs["USD - RUB"] / currencyPairs["CNY - RUB"];
      currencyPairs["AED - USD"] = currencyPairs["AED - RUB"] / currencyPairs["USD - RUB"];
      currencyPairs["USD - AED"] = currencyPairs["USD - RUB"] / currencyPairs["AED - RUB"];
  
      setCbrRates(currencyPairs);
      setEditableCbrRates(currencyPairs);
  
      console.log("Полученные курсы валют:", currencyPairs);
    } catch (error) {
      console.error("Error fetching CBR rates:", error);
    }
  };
  

  // Функция для получения курсов валют от Econo API
  const fetchEconoRates = async () => {
    try {
      const response = await fetch("https://api.exchangerate-api.com/v4/latest/RUB");
      const data = await response.json();

      const currencyPairs = {
        "USD - RUB": 1 / data.rates.USD,
        "RUB - USD": data.rates.USD,
        "EUR - RUB": 1 / data.rates.EUR,
        "RUB - EUR": data.rates.EUR,
        "CNY - RUB": 1 / data.rates.CNY,
        "RUB - CNY": data.rates.CNY,
        "AED - RUB": 1 / data.rates.AED,
        "RUB - AED": data.rates.AED,

        "EUR - USD": data.rates.EUR / data.rates.USD,
        "USD - EUR": data.rates.USD / data.rates.EUR,
        "CNY - USD": data.rates.CNY / data.rates.USD,
        "USD - CNY": data.rates.USD / data.rates.CNY,
        "AED - USD": data.rates.AED / data.rates.USD,
        "USD - AED": data.rates.USD / data.rates.AED,
      };

      setEconoRates(currencyPairs);
      setEditableEconoRates(currencyPairs);
    } catch (error) {
      console.error("Error fetching Econo API rates:", error);
    }
  };

    // Функция для сохранения курсов валют
    const SaveCurrency = async (source, rates) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/currency/update`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            currencyData: rates,
            source: source, // 'CBR' или 'ECONO'
          }),
        });
    
        if (response.ok) {
          console.log(`Курсы валют из ${source} успешно отправлены на сервер`);
          alert(`Курсы валют из ${source} сохранены на сервере!`);
        } else {
          console.error(`Ошибка при отправке данных из ${source}`);
          alert("Ошибка при сохранении данных.");
        }
      } catch (error) {
        console.error("Ошибка сети:", error);
        alert("Ошибка сети при отправке данных.");
      }
    };
  
    const handleInputChangeCbr = (key, value) => {
      setEditableCbrRates((prevRates) => ({
        ...prevRates,
        [key]: value,
      }));
    };
  
    const handleInputChangeEcono = (key, value) => {
      setEditableEconoRates((prevRates) => ({
        ...prevRates,
        [key]: value,
      }));
    };

  return (
    <>
    <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
      <IconBox
        w="56px"
        h="56px"
        bg="white"
        float="left"
        icon={<Icon w="32px" h="32px" as={MdHandshake} color="navy.500" />}
      />
      <Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">
        Курсы валют
      </Text>
    </Flex>
  
    <Card w="100%" p={{ base: "20px", md: "40px" }}>
        <HStack align="start" spacing={8} p={4}>
          {/* Блок ЦБР */}
          <Box w="50%">
            <Button colorScheme="blue" onClick={fetchCbrRates} mb={4}>
              Получить курсы валют (ЦБР)
            </Button>
            {cbrRates && (
              <VStack align="start" spacing={3}>
                <Text fontWeight="bold">Курсы валют от ЦБР:</Text>
                {Object.entries(editableCbrRates).map(([pair, rate]) => (
                  <HStack key={pair}>
                    <Text>{pair}:</Text>
                    <Input
                      type="number"
                      value={rate}
                      onChange={(e) => handleInputChangeCbr(pair, e.target.value)}
                      width="100px"
                    />
                  </HStack>
                ))}
                <Button colorScheme="green" onClick={() => SaveCurrency("CBR", editableCbrRates)}>
                  Сохранить
                </Button>
              </VStack>
            )}
          </Box>

          <Divider orientation="vertical" />

          {/* Блок Econo API */}
          <Box w="50%">
            <Button colorScheme="blue" onClick={fetchEconoRates} mb={4}>
              Получить курсы валют (Econo API)
            </Button>
            {econoRates && (
              <VStack align="start" spacing={3}>
                <Text fontWeight="bold">Курсы валют от Econo API:</Text>
                {Object.entries(editableEconoRates).map(([pair, rate]) => (
                  <HStack key={pair}>
                    <Text>{pair}:</Text>
                    <Input
                      type="number"
                      value={rate}
                      onChange={(e) => handleInputChangeEcono(pair, e.target.value)}
                      width="100px"
                    />
                  </HStack>
                ))}
                <Button colorScheme="green" onClick={() => SaveCurrency("ECONO", editableEconoRates)}>
                  Сохранить
                </Button>
              </VStack>
            )}
          </Box>
        </HStack>
      </Card>
    </>
  );
};

export default CurrencyParser;
