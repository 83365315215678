import React, { useEffect, useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Input,
  Button,
  Box,
  Icon,
  Flex,
  IconButton,
  Text,
} from "@chakra-ui/react";
import { ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import IconBox from "components/icons/IconBox";
import { MdPostAdd } from "react-icons/md";

const OrderTable = () => {
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [isChanged, setIsChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 30;

  // Функция для форматирования даты
  const formatDate = (isoDate) => {
    const date = new Date(isoDate);
    return date.toLocaleDateString("ru-RU", {
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });
  };

  const fetchOrders = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/orders?page=${currentPage}&limit=${itemsPerPage}`
      );
      const result = await response.json();
      setData(result.orders);
      setOriginalData(result.orders);
      setIsChanged(false);
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchOrders();
  }, [currentPage]);

  const handleInputChange = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value };
    setData(newData);
    setIsChanged(true);
  };

  const handleSave = async () => {
    setIsLoading(true);
    const updatedOrders = {};
    data.forEach((row, index) => {
      const originalRow = originalData[index];
      const updatedFields = {};

      Object.keys(row).forEach((field) => {
        if (row[field] !== originalRow[field]) {
          updatedFields[field] = row[field];
        }
      });

      if (Object.keys(updatedFields).length > 0) {
        updatedOrders[row.client_id] = updatedFields;
      }
    });

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/admin/orders/update`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ updatedOrders }),
        }
      );

      if (response.ok) {
        alert("Данные успешно обновлены!");
        fetchOrders();
      } else {
        console.error("Ошибка при сохранении данных.");
      }
    } catch (error) {
      console.error("Ошибка сети:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const nextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));

  return (
    <>
      <Flex pt={{ base: "60px", md: "0" }} alignItems="center" mb="40px">
        <IconBox
          w="56px"
          h="56px"
          bg="white"
          float="left"
          icon={<Icon w="32px" h="32px" as={MdPostAdd} color="navy.500" />}
        />
        <Text pl="20px" fontSize="30px" fontWeight="bold" lineHeight="140%">
          Заявки
        </Text>
      </Flex>

      <Card w="100%" p={{ base: "20px", md: "40px" }}>
        <Box overflowX="auto">
          <Table variant="striped" size="sm">
            <Thead>
              <Tr>
                <Th>Дата</Th>
                {data.length > 0 &&
                  Object.keys(data[0])
                    .filter((key) => key !== "datum") // Исключаем дату из общих колонок
                    .map((key) => <Th key={key}>{key}</Th>)}
              </Tr>
            </Thead>
            <Tbody>
              {data.map((order, index) => (
                <Tr key={index}>
                  {/* Нередактируемая колонка с датой */}
                  <Td>{formatDate(order["datum"])}</Td>
                  {/* Остальные редактируемые колонки */}
                  {Object.keys(order)
                    .filter((field) => field !== "datum") // Исключаем "datum"
                    .map((field) => (
                      <Td key={field}>
                        <Input
                          size="sm"
                          value={order[field] || ""}
                          onChange={(e) =>
                            handleInputChange(index, field, e.target.value)
                          }
                        />
                      </Td>
                    ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>

        <Flex mt={4} justify="center" align="center">
          <IconButton
            icon={<ArrowLeftIcon />}
            onClick={prevPage}
            isDisabled={currentPage === 1}
            mr={4}
          />
          <Text fontSize="sm">
            Страница {currentPage} из {totalPages || 1}
          </Text>
          <IconButton
            icon={<ArrowRightIcon />}
            onClick={nextPage}
            isDisabled={currentPage === totalPages}
            ml={4}
          />
        </Flex>

        <Button
          position="fixed"
          bottom="20px"
          right="20px"
          colorScheme="blue"
          size="lg"
          fontSize="15px"
          visibility={isChanged ? "visible" : "hidden"}
          isLoading={isLoading}
          onClick={handleSave}
        >
          Сохранить изменения
        </Button>
      </Card>
    </>
  );
};

export default OrderTable;
