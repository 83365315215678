const API_BASE_URL = "https://api.nppu.ru/chats"; // Замените на ваш URL API

// Общая функция для обработки fetch-запросов
const fetchData = async (url, options = {}) => {
  const response = await fetch(url, options);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
};

// Получить список чатов пользователя
export const getUserChats = async (userId) => {
  return await fetchData(`${API_BASE_URL}/chats/user/${userId}`);
};

export const fetchChatByOrderId = async (orderId) => {
  try {
    const response = await fetch(`${API_BASE_URL}/chats/order/${orderId}`);
    if (!response.ok) {
      throw new Error("чат не найден");
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    return null;
  }
};

// Получить сообщения в чате
export const getChatMessages = async (chatId) => {
  return await fetchData(`${API_BASE_URL}/chats/${chatId}/messages`);
};

// Отправить сообщение
export const sendMessage = async (chatId, messageData) => {

  console.log("Отправка сообщения:", { chatId, messageData });
  const response = await fetch(`${API_BASE_URL}/chats/${chatId}/messages`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json", // Указываем, что отправляем JSON
    },
    body: JSON.stringify(messageData), // Преобразуем объект messageData в строку JSON
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return await response.json();
};

export const subscribeToChatEvents = (chatId, onMessage, onError) => {
  const eventSource = new EventSource(`${API_BASE_URL}/chats/${chatId}/events`);

  // Обработка полученных сообщений
  eventSource.onmessage = (event) => {
    const message = JSON.parse(event.data);
    console.log("Message received on client:", message); 
    if (onMessage) {
      onMessage(message);
    }
  };

  // Обработка ошибок SSE
  eventSource.onerror = (error) => {
    console.error("SSE connection error:", error);
    if (onError) {
      onError(error);
    }
    eventSource.close(); // Закрываем соединение при ошибке
  };

  // Возвращаем объект EventSource, чтобы клиент мог закрыть соединение
  return eventSource;
};

