import React, { createContext, useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useToast } from "@chakra-ui/react"; // Импортируем useToast из Chakra UI
import CryptoJS from "crypto-js";

const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;

export const UserContext = createContext();

const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_KEY).toString();
};

const decryptData = (encryptedData) => {
  try {
    const bytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (error) {
    console.error("Ошибка расшифровки данных:", error);
    return null;
  }
};

export const UserProvider = ({ children }) => {
  const navigate = useNavigate();
  const toast = useToast();
  const [user, setUser] = useState(null);
  const [agent, setAgent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const hasEffectRun = useRef(false);
  const location = useLocation();


  useEffect(() => {
    if (location.pathname.includes("admin") && !hasEffectRun.current) {
      hasEffectRun.current = true;
    const encryptedUser = localStorage.getItem("user");
    const encryptedAgent = localStorage.getItem("agent");

    if (encryptedUser) {
      try {
        const decryptedUser = decryptData(encryptedUser);
        setUser(decryptedUser); // Устанавливаем данные пользователя
      } catch (error) {
        console.error("Ошибка расшифровки данных пользователя:", error);
        localStorage.removeItem("user");
      }
    } else {
      // Если данных нет в localStorage, выполняем запрос для получения пользователя
      fetchUserFromToken();
    }

    if (encryptedAgent) {
      try {
        const decryptedAgent = decryptData(encryptedAgent);
        setAgent(decryptedAgent); // Устанавливаем данные агента
      } catch (error) {
        console.error("Ошибка расшифровки данных агента:", error);
        localStorage.removeItem("agent");
      }
    }

    setIsLoading(false);
  }
  }, [location.pathname]); 

  useEffect(() => {
    // Загружаем данные агента, если пользователь есть
    if (user && user.agent_id && !agent) {
      getAgentData(user.agent_id); // Загружаем данные агента по user.agent_id
    }
  }, [user]);

  // Функция для получения данных пользователя
  const fetchUserFromToken = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/profile`, {
        method: "GET",
        credentials: "include", // Используем куки
      });

      if (!response.ok) {
        setUser(null);
        return;
      }

      const userData = await response.json();
      setUser(userData); // Устанавливаем данные пользователя
      const encryptedData = encryptData(userData);
      localStorage.setItem("user", encryptedData); // Сохраняем данные в localStorage
    } catch (error) {
      console.error("Ошибка при получении данных пользователя:", error);
      setUser(null);
    } finally {
      setIsLoading(false); // Завершаем загрузку
    }
  };


  const register = async (email, clientType, INN, agentId, name) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/register`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, clientType, INN, agentId, name }),
       
      });
      const data = await response.json();
 
      if (!response.ok) {
        // Если регистрация не успешна, показываем toast с ошибкой
        toast({
          title: "Ошибка регистрации",
          description: data.message || "Произошла ошибка при регистрации.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      if (response.ok) {


        // Показать toast-сообщение
        toast({
          title: "Успешная регистрация!",
          description: "На вашу почту отправлено письмо с подтверждением регистрации.",
          status: "success",
          duration: 5000, // Продолжительность отображения в миллисекундах
          isClosable: true,
        });

        navigate("/auth/sign-in");
      } else {
      }
    } catch (error) {
      console.error("Ошибка регистрации:", error);
    }
  };

  const confirm = async (token) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/confirm/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        const { client_id, is_active } = data; // Извлечение данных
  
        // Показать toast-сообщение
        toast({
          title: "Регистрация подтверждена!",
          description: `Ваш аккаунт успешно активирован.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
  
        return { client_id, is_active }; // Возвращаем данные
      } else {
        // Показать toast-сообщение об ошибке
        toast({
          title: "Ошибка подтверждения",
          description: data.message || "Не удалось подтвердить регистрацию.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
  
        return null; // Возврат null при ошибке
      }
    } catch (error) {
      console.error("Ошибка подтверждения регистрации:", error);
  
      // Показать toast-сообщение об ошибке
      toast({
        title: "Ошибка подтверждения",
        description: "Произошла ошибка при подтверждении регистрации.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
  
      return null; // Возврат null при исключении
    }
  };
  

  const createAccount = async ({ userId, login, password }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/create-account`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, login, password }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: "Аккаунт создан",
          description: "Вы успешно зарегистрировались!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Ошибка регистрации",
          description: data.message || "Не удалось создать аккаунт.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Ошибка создания аккаунта:", error);
      toast({
        title: "Ошибка",
        description: "Произошла ошибка при создании аккаунта.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const passwordRecovery = async ({ email }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/recovery`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      if (response.ok) {
        toast({
          title: "Восстановление пароля",
          description: "Письмо отправлено на ваш email!",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Ошибка восттановления",
          description: "Пользователь с таким email не найден",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Ошибка восстановления", error);
    }
  };

  const passwordChange = async ({ userId, password, token }) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/password-change/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          password,
          client_id: userId
        }),
      });
  
      if (response.ok) {
        toast({
          title: "Пароль изменен успешно",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        toast({
          title: `Ошибка: ${errorData.message || "При изменении пароля"}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Ошибка при изменении пароля",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const sendOrganizationData = async ({ inn, bic, website }) => {
    const client_id = user.client_id;
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/organization`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ client_id, inn, bic, website }),
      });
  
      const result = await response.json();
  
      if (response.ok) {
        // Обновляем пользователя в локальном состоянии
        const updatedUser = { ...user, ...result }; // Объединяем старые и новые данные
        setUser(updatedUser);
  
        // Сохраняем обновленные данные в localStorage
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(updatedUser),
          SECRET_KEY
        ).toString();
        localStorage.setItem("user", encryptedData);
  
        toast({
          title: "Данные обновлены",
          description: "Информация об организации успешно обновлена.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
  
        return result; // Возвращаем результат
      } else {
        throw new Error(result.message || "Ошибка при обновлении данных организации");
      }
    } catch (error) {
      console.error("Ошибка при отправке данных:", error);
  
      toast({
        title: "Ошибка обновления",
        description: "Не удалось обновить информацию об организации.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
  
      throw error; // Прокидываем ошибку дальше
    }
  };
  
  const login = async (identifier, password) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ identifier, password }),
        credentials: "include", // Включаем куки
      });

      const data = await response.json();

      if (response.ok) {
        setUser(data);
        const encryptedData = encryptData(data);
    

        Promise.resolve()
  .then(() => {
    localStorage.setItem("user", encryptedData);
  })
  .then(() => {
    toast({
      title: "Успешный вход",
      description: "Вы успешно вошли в систему!",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
 console.log(data?.client_id)
    if (data?.client_id != 1) {
      navigate("/admin/default"); // Если client_id не равен 1
    } else {
      navigate("/admin/protected/orders"); // Если client_id равен 1
    }
  })
      } else {
        toast({
          title: "Ошибка входа",
          description: data.message || "Неверные учетные данные.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error("Ошибка входа:", error);
      toast({
        title: "Ошибка входа",
        description: "Произошла ошибка при входе в систему.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  // Функция для выхода из системы
  const logout = async () => {
    try {
      await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/logout`, {
        method: "POST",
        credentials: "include",
      });

      setUser(null);
      setAgent(null);
      localStorage.removeItem("user");
      localStorage.removeItem("agent");
      navigate("/auth/sign-in");

      toast({
        title: "Выход выполнен",
        description: "Вы успешно вышли из системы.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Ошибка выхода:", error);
      toast({
        title: "Ошибка выхода",
        description: "Произошла ошибка при выходе из системы.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateUserField = async (field, value) => {
   
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/update-field`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include", // Для передачи куки
        body: JSON.stringify({ field, value, client_id: user?.client_id }),
      });
    
      const result = await response.json();
   
      if (response.ok) {
        // Обновляем состояние пользователя
        const updatedUser = { ...user, [field]: value };
        setUser(updatedUser);
  
        // Сохраняем обновленные данные в localStorage
        const encryptedData = CryptoJS.AES.encrypt(
          JSON.stringify(updatedUser),
          SECRET_KEY
        ).toString();
        localStorage.setItem("user", encryptedData);
  
        toast({
          title: "Успешно обновлено",
          description: `Поле ${field} успешно обновлено.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(result.message || "Ошибка обновления");
      }
    } catch (error) {
      console.error("Ошибка при обновлении поля:", error.message);
  
      toast({
        title: "Ошибка обновления",
        description: `Не удалось обновить поле ${field}.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/change-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          oldPassword,
          newPassword,
          client_id: user?.client_id
        }),
      });
  
      if (response.ok) {
        toast({
          title: "Пароль изменен успешно",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      } else {
        const errorData = await response.json();
        toast({
          title: `Ошибка: ${errorData.message || "При изменении пароля"}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    } catch (error) {
      toast({
        title: "Ошибка при изменении пароля",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  const getAgentData = async (clientId = user?.agent_id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/users/agent/${clientId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const agentData = await response.json();
        setAgent(agentData);
        const encryptedData = encryptData(agentData);
        localStorage.setItem("agent", encryptedData);
      } else {
        console.error("Ошибка при получении данных агента");
      }
    } catch (error) {
      console.error("Ошибка сети или сервера:", error);
    }
  };
  
 
  
  

  return (
    <UserContext.Provider value={{ user, agent, register, logout, login, confirm, createAccount, sendOrganizationData, changePassword, updateUserField, getAgentData, passwordChange, passwordRecovery, isLoading }}>
      {children}
    </UserContext.Provider>
  );
};
