import React, { useState } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import "./Upload.css";

// Подключение плагинов FilePond
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

// Регистрация плагинов
registerPlugin(FilePondPluginImagePreview, FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const FileUpload = ({ setIsFileUploaded, uploadType, onFileUploaded }) => {
    const [files, setFiles] = useState([]);
  
    return (
      <div>
        <FilePond
          files={files}
          onupdatefiles={(fileItems) => {
            setFiles(fileItems);
            setIsFileUploaded(fileItems.length > 0); // Устанавливаем флаг загрузки
          }}
          allowMultiple={false}
          maxFiles={5}
          name="filepond"
          labelIdle='Перетащите файлы сюда<br>или <span class="filepond--label-action">загрузите их</span>'
          acceptedFileTypes={[
            "application/pdf",
            "application/vnd.ms-excel",
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/msword",
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
            "image/jpeg",
            "image/png",
          ]}
          maxFileSize="5MB"
          server={{
            process: (fieldName, file, metadata, load, error, progress, abort) => {
              const formData = new FormData();
              formData.append(fieldName, file, file.name);
              formData.append("uploadType", uploadType); // Передаем тип загрузки
  
              const request = new XMLHttpRequest();
              request.open("POST", `${process.env.REACT_APP_API_BASE_URL}/api/files/upload`);
  
              request.upload.onprogress = (e) => {
                progress(e.lengthComputable, e.loaded, e.total);
              };
  
              request.onload = () => {
                if (request.status >= 200 && request.status < 300) {
                  const response = JSON.parse(request.responseText);
                  load(response.fileName); // Завершаем загрузку
                  if (onFileUploaded) {
                    onFileUploaded(response.fileName); // Вызываем коллбэк с именем файла
                  }
                } else {
                  error("Ошибка загрузки файла");
                }
              };
  
              request.send(formData);
  
              return {
                abort: () => {
                  request.abort();
                  abort();
                },
              };
            },
            revert: (uniqueFileId, load, error) => {
              load(); // Успешное удаление файла
            },
          }}
        />
      </div>
    );
  };
  

export default FileUpload;